import React, { useEffect, useState } from 'react'
import './ResultsSearch.scss'
import { getFilesResults } from '../../service/apiService'
import { Box, Button, Checkbox, SearchForm } from '@bosch/react-frok'
import { IUserResultsSearchProps } from '../../interfaces/IUserResultsProps'
import {
  handleChange,
  handleCheckAll,
  handleSortPriority,
  handleUncheckAll,
  orderSortByString,
  searchByString,
  toggleSortOrder,
} from '../../service/sortFilesUtils'

const ResultsSearch: React.FunctionComponent<IUserResultsSearchProps> = ({
  setFilesResults,
  setResultsNumberOfPages,
  userToken,
  resultsPageNumber,
  resultsPageLimit,
  searchResultsFilter,
  setSearchResultsFilter,
}) => {
  const [searchResultString, setSearchResultString] = useState<string>('')
  const [searchResultClicked, setSearchResultClicked] = useState(false)
  const [checkboxes, setCheckboxes] = useState([
    { label: 'Name', value: 'fileId', checked: true, order: 'ASC', priority: 0 },
    { label: 'Date', value: 'date', checked: true, order: 'DESC', priority: 1 },
    { label: 'Petrol Station', value: 'petrolStation', checked: true, order: 'ASC', priority: 4 },
    { label: 'Fuel Type', value: 'typeFuel', checked: true, order: 'ASC', priority: 3 },
    { label: 'Address', value: 'address', checked: true, order: 'ASC', priority: 5 },
    { label: 'Currency', value: 'currency', checked: true, order: 'ASC', priority: 6 },
    { label: 'Paid', value: 'paid', checked: true, order: 'ASC', priority: 2 },
  ])

  const checkedResultsString = searchByString(checkboxes)
  const orderResultsString = orderSortByString(checkboxes)

  const handleSearchString = (event: any) => {
    setSearchResultString(event.target.value)
  }

  const handleFilesSearch = () => {
    setSearchResultsFilter(searchResultString)
  }

  const handleResetSearch = () => {
    setSearchResultsFilter('')
  }

  const handleSearchByClick = () => {
    setSearchResultClicked(!searchResultClicked)
  }

  useEffect(() => {
    getFilesResults(
      setFilesResults,
      setResultsNumberOfPages,
      userToken,
      resultsPageNumber,
      resultsPageLimit,
      searchResultsFilter,
      checkedResultsString,
      orderResultsString,
    )
  }, [resultsPageNumber, searchResultsFilter, checkedResultsString, orderResultsString])

  return (
    <>
      <div className="search-results-filters">
        <div className="search">
          <SearchForm
            onChange={handleSearchString}
            onReset={handleResetSearch}
            onSubmit={handleFilesSearch}
            searchField={{
              id: 'searchFieldId',
            }}
            suggestions={null}
          />
        </div>
        <div className={searchResultClicked ? 'search-by-button-open' : 'search-by-button'}>
          <Button
            onClick={handleSearchByClick}
            icon={{
              iconName: 'arrow-up-down',
              title: 'File Order',
            }}
            label="Search and Order Results by"
          />
        </div>
      </div>
      {searchResultClicked ? (
        <Box shadow className="search-and-order-by-box-results">
          <div className="select-all">
            <p
              className="select-paragraph"
              onClick={() => {
                handleCheckAll(checkboxes, setCheckboxes)
              }}
            >
              Check All
            </p>
            <p
              className="select-paragraph"
              onClick={() => {
                handleUncheckAll(checkboxes, setCheckboxes)
              }}
            >
              Uncheck All
            </p>
            <p className="results-priority-paragraph">Sort Order</p>
          </div>
          <div className="checkboxes-and-orders-results">
            {checkboxes.map((checkbox, index) => (
              <div key={'search' + index} className="checkbox-and-order-results">
                <Checkbox
                  checked={checkbox.checked}
                  onChange={() => handleChange(index, checkboxes, setCheckboxes)}
                  id={`checkbox-results-${index}`}
                  label={checkbox.label}
                  className="checkbox"
                />
                <p
                  className={`order-asc ${checkbox.order === 'ASC' ? 'selected' : ''}`}
                  onClick={() => toggleSortOrder(index, setCheckboxes)}
                >
                  ASC
                </p>
                <p
                  className={`order-desc ${checkbox.order === 'DESC' ? 'selected' : ''}`}
                  onClick={() => toggleSortOrder(index, setCheckboxes)}
                >
                  DESC
                </p>
                <input
                  className="sort-order-results-priority"
                  id={`results-priority-${index}`}
                  type="number"
                  min="-10"
                  max="10"
                  value={checkbox.priority.toString()}
                  onChange={(e) => handleSortPriority(index, e.target.value, checkboxes, setCheckboxes)}
                />
              </div>
            ))}
          </div>
        </Box>
      ) : null}
    </>
  )
}

export default ResultsSearch
