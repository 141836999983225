import React, { useEffect, useState } from 'react'
import { Box, ProgressIndicator } from '@bosch/react-frok'
import './MainUploadBox.scss'
import UploadButton from '../../atoms/UploadButton/UploadButton'
import Dropzone from '../../molecules/Dropzone/Dropzone'
import { IMainUserProps } from '../../interfaces/IUserFilesProps'
import { Status } from '../../types/Status'
import { getUploadMessage } from '../../service/uploadHandlers'
import FileWarning from '../../molecules/FileWarning/FileWarning'
import { handleCanBeUploaded } from '../../service/imageQuality'

const MainUploadBox: React.FunctionComponent<IMainUserProps> = ({ userToken }) => {
  const [newFilesToUpload, setNewFilesToUpload] = useState<File[]>([])
  const [uploadStatus, setUploadStatus] = useState<Status>('')
  const [canBeUploaded, setCanBeUploaded] = useState(true)
  const [warningMessage, setWarningMessage] = useState<string>('')
  // const [checkQuality, setCheckQuality] = useState(false)
  const checkQuality = false
  const [uploadImage, setUploadImage] = useState<string | undefined>(undefined)

  useEffect(() => {
    handleCanBeUploaded(checkQuality, warningMessage, setWarningMessage, setCanBeUploaded)
  }, [warningMessage, checkQuality])

  useEffect(() => {
    setUploadStatus('')
  }, [newFilesToUpload])

  // const handleQualityCheckbox = () => {
  //   setCheckQuality(!checkQuality)
  //   if (uploadImage !== undefined) {
  //     imageReadAndCheck(newFilesToUpload[0], setWarningMessage)
  //   }
  // }

  return (
    <div className="main-upload">
      <Box>
        <FileWarning warningMessage={warningMessage} />
        <div className="box-content">
          <h4 className="title">Upload Files to Azure Blob Storage</h4>
          <Dropzone
            uploadImage={uploadImage}
            setUploadImage={setUploadImage}
            setNewFilesToUpload={setNewFilesToUpload}
            setWarningMessage={setWarningMessage}
          />

          <p id="progress-paragraph">{getUploadMessage(uploadStatus, newFilesToUpload[0])}</p>
          {uploadStatus === 'start' ? <ProgressIndicator type="indeterminate" /> : null}
          {/* {newFilesToUpload[0] ? <Checkbox id="image-quality" label="Check Image Quality" onClick={handleQualityCheckbox} /> : null} */}
          <UploadButton
            newFilesToUpload={newFilesToUpload}
            userToken={userToken}
            setUploadStatus={setUploadStatus}
            canBeUploaded={canBeUploaded}
            setUploadImage={setUploadImage}
          />
        </div>
      </Box>
    </div>
  )
}

export default MainUploadBox
