import React from 'react'
import { Table, TableHead, TableRow, TableCell, TableBody } from '@bosch/react-frok'
import { IResultsTableProps } from '../../../interfaces/ITableProps'
import './FileResultsTable.scss'
import FileResultsTableCells from '../FileResultsTableCell'

const FileResultsTable: React.FunctionComponent<IResultsTableProps> = ({ results }) => {
  return (
    <div className="file-results-table">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell header secondary>
              File
            </TableCell>
            <TableCell header secondary>
              Petrol Station
            </TableCell>
            <TableCell header secondary>
              Date
            </TableCell>
            <TableCell header secondary>
              Currency
            </TableCell>
            <TableCell header secondary>
              Paid
            </TableCell>
            <TableCell header secondary>
              Fuel Type
            </TableCell>
            <TableCell header secondary>
              Address
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((result) => (
            <TableRow key={result.fileId + 'results'}>
              <FileResultsTableCells result={result} />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default FileResultsTable
