import React, { useEffect, useState } from 'react'
import { MinimalHeader, SideNavigation } from '@bosch/react-frok'
import { INavigationProps } from '../interfaces/INavigationProps'
import { fetchUserAttributes, signOut } from 'aws-amplify/auth'

const Navigation: React.FunctionComponent<INavigationProps> = ({ pageTitle }) => {
  const [externalEmail, setExternalEmail] = useState<string>('No active account')

  const checkCognitoCredentials = async () => {
    try {
      const account = await fetchUserAttributes()
      setExternalEmail(account.email || 'No active account')
    } catch (error) {
      return null
    }
  }

  useEffect(() => {
    checkCognitoCredentials()
  }, [])

  const logout = () => {
    signOut()
    window.location.reload()
  }

  const actions = [
    {
      icon: 'user',
      label: externalEmail,
      showLabel: true,
      value: 'user',
    },
  ]

  const menuItems = [
    {
      icon: 'logout',
      label: 'Logout',
      link: {
        action: 'submit',
        as: 'button',
        onClick: logout,
      },
      value: 'log out',
    },
  ]

  return (
    <div className="main-header">
      <MinimalHeader
        actions={actions}
        logo={{
          'aria-label': 'My Logo',
          href: 'https://bosch.com',
          target: '_blank',
          title: 'My Logo',
        }}
        sideNavigation={
          <SideNavigation
            body={{
              menuItems: menuItems,
            }}
            header={{
              label: 'Validation Handshake',
            }}
          />
        }
      >
        {pageTitle}
      </MinimalHeader>
    </div>
  )
}

export default Navigation
