import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import '@bosch/frontend.kit-npm/dist/frontend-kit.css'
import { AuthProvider } from 'react-oidc-context'
import oidcConfig from './config/oidcConfig'
import { AwsConfigAuth } from './config/awsConfig'
import { Amplify, ResourcesConfig } from 'aws-amplify'

Amplify.configure(AwsConfigAuth as ResourcesConfig)
const AppProvider = () => (
  <AuthProvider {...oidcConfig}>
    <App />
  </AuthProvider>
)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <AppProvider />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
