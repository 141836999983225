import React, { useState } from 'react'
import Navigation from '../../organisms/Navigation'
import MainUploadBox from '../../organisms/MainUploadBox/MainUploadBox'
import { IHomePageProps } from '../../interfaces/IHomePageProps'
import UserFiles from '../../organisms/UserFiles/UserFiles'
import UserFileResults from '../../organisms/UserFileResults/UserFileResults'
import FileMetadata from '../../types/FileMetadata'
import { PageIndicator } from '@bosch/react-frok'
import './HomePage.scss'
import FileResults from '../../types/Output'

const HomePage: React.FunctionComponent<IHomePageProps> = ({ userToken }) => {
  const pageTitle = 'Homepage'
  const [filesMetadata, setFilesMetadata] = useState<FileMetadata[]>([])
  const [filesResults, setFilesResults] = useState<FileResults[]>([])

  const [filesPageNumber, setFilesPageNumber] = useState<number>(1)
  const [resultsPageNumber, setResultsPageNumber] = useState<number>(1)

  const [filesNumberOfPages, setFilesNumberOfPages] = useState<number>(1)
  const [resultsNumberOfPages, setResultsNumberOfPages] = useState<number>(1)

  const filesPageLimit = 10
  const resultsPageLimit = 10

  const handlePageClick = (event: any) => {
    setFilesPageNumber(event.currentTarget.dataset.index)
  }

  const handleResultsPageClick = (event: any) => {
    setResultsPageNumber(event.currentTarget.dataset.index)
  }

  return (
    <>
      <Navigation pageTitle={pageTitle} />
      <MainUploadBox userToken={userToken} />
      <UserFiles
        filesMetadata={filesMetadata}
        setFilesMetadata={setFilesMetadata}
        setFilesNumberOfPages={setFilesNumberOfPages}
        userToken={userToken}
        filesPageLimit={filesPageLimit}
        filesPageNumber={filesPageNumber}
      />
      <div id="file-page-indicator">
        <PageIndicator numbered onPageSelect={handlePageClick} defaultSelected={1} pages={filesNumberOfPages} />
      </div>
      <UserFileResults
        results={filesResults}
        setFilesResults={setFilesResults}
        setResultsNumberOfPages={setResultsNumberOfPages}
        userToken={userToken}
        resultsPageLimit={resultsPageLimit}
        resultsPageNumber={resultsPageNumber}
      />
      <div id="results-page-indicator">
        <PageIndicator numbered onPageSelect={handleResultsPageClick} defaultSelected={1} pages={resultsNumberOfPages} />
      </div>
    </>
  )
}

export default HomePage
