import env from 'react-dotenv'

const environment = env.ENVIRONMENT_TYPE || 'Local'

let API_KEY: string
let REDIRECT_URI: string

//AWS Cognito for externals
const AWS_AUTH_REGION = 'eu-central-1'
const AWS_AUTH_USER_ID = 'eu-central-1_cYJwmZFIs'
const AWS_AUTH_CLIENT_ID = '1392jca07vbp80o1fgo3klteov'
const AWS_AUTH_DOMAIN = 'https://cpsdft.auth.eu-central-1.amazoncognito.com'

switch (environment) {
  case 'Development':
    API_KEY = process.env.API_KEY || env.API_KEY || 'api key undefined'
    REDIRECT_URI = 'https://validation-ui.development.digitalfueltwin-solutions.com/'
    break
  case 'Local':
    API_KEY = process.env.REACT_APP_API_KEY || 'api key undefined'
    REDIRECT_URI = 'http://localhost:3000'
    break
  default:
    API_KEY = process.env.API_KEY || 'api key undefined'
    REDIRECT_URI = 'https://validation-ui.development.digitalfueltwin-solutions.com/'
    break
}

export const environmentConstants = {
  API_KEY,
  REDIRECT_URI,
  AWS_AUTH_REGION,
  AWS_AUTH_USER_ID,
  AWS_AUTH_CLIENT_ID,
  AWS_AUTH_DOMAIN,
}
