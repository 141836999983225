import React, { useCallback, useState } from 'react'
import { Box, Icon } from '@bosch/react-frok'
import './Dropzone.scss'
import { useDropzone } from 'react-dropzone'
import { IDropzoneProps } from '../../interfaces/IDropzoneProps'
import { imageReadAndCheck } from '../../service/imageQuality'

export const handleFileText = (filename: string): string => {
  return filename.length > 27 ? filename.slice(0, 27) + '...' : filename
}

const Dropzone: React.FunctionComponent<IDropzoneProps> = ({ uploadImage, setUploadImage, setNewFilesToUpload, setWarningMessage }) => {
  const [droppedFiles, setDroppedFiles] = useState<File[]>([])

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setDroppedFiles(acceptedFiles)
      setNewFilesToUpload(acceptedFiles)
      const droppedFile = acceptedFiles[0]

      if (droppedFile.type === 'image/png' || droppedFile.type === 'image/jpeg') {
        imageReadAndCheck(droppedFile, setWarningMessage)
        const imageUrl = URL.createObjectURL(droppedFile)
        setUploadImage(imageUrl)
      } else {
        setWarningMessage('File is not an image')
      }
    },
    [setNewFilesToUpload],
  )

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <div className="dropzone">
      <div {...getRootProps()} data-testid="dropzone-test">
        <Box>
          <div className="dropzone-box">
            <center>
              {uploadImage === undefined ? (
                <div className="no-image">
                  <Icon iconName="cloud-upload" />
                  <p id="dropzone-paragraph">Drag and Drop files here or </p>
                </div>
              ) : (
                <img className="dropzone-image" data-testid="dropzone-image-test" src={uploadImage} alt="Uploaded" />
              )}
              <p id="choose-file-paragraph">Choose File</p>

              <div className="dropzone-files">
                {droppedFiles.length > 0 ? (
                  <div className="dropzone-files-name" key={droppedFiles[0].name}>
                    {handleFileText(droppedFiles[0].name)}
                  </div>
                ) : null}
              </div>
            </center>
          </div>
        </Box>
        <input {...getInputProps()} className="mobile-select-file" />
      </div>
    </div>
  )
}

export default Dropzone
