import { Params } from '../types/Params'

const defaultResultsOrder =
  'sortBy=fileId:ASC&sortBy=date:ASC&sortBy=petrolStation:ASC&sortBy=typeFuel:ASC&sortBy=address:ASC&sortBy=currency:ASC&sortBy=paid:ASC'

export const getResultsQueryParams = (
  resultsPageNumber: number,
  resultsPageLimit: number,
  searchResultsFilter: string,
  searchBy: string,
  sortBy: string,
): string => {
  const params: Params = {
    page: resultsPageNumber,
    limit: resultsPageLimit,
    search: searchResultsFilter,
  }

  let queryParams = 'page=' + resultsPageNumber + '&limit=' + resultsPageLimit

  if (searchResultsFilter !== '') {
    queryParams = Object.keys(params)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
      .join('&')

    if (searchBy !== '') {
      queryParams = queryParams + '&searchBy=' + searchBy
    } else {
      queryParams = queryParams + '&searchBy=fileId'
    }

    if (sortBy !== '' && sortBy !== defaultResultsOrder) {
      queryParams = queryParams + '&' + sortBy
    }

    return queryParams
  } else {
    if (sortBy !== '' && sortBy !== defaultResultsOrder) {
      queryParams = queryParams + '&' + sortBy
      return queryParams
    }

    return queryParams + defaultResultsOrder
  }
}
