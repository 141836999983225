import React from 'react'
import { ILoginProps } from '../../interfaces/ILoginProps'
import { Box } from '@bosch/react-frok'
import ExternalForm from './External/ExternalForm'
import './LoginPage.scss'

const LoginPage: React.FunctionComponent<ILoginProps> = () => {
  return (
    <div className="login-page">
      <Box className="auth-box" open>
        <div className="auth-box-content">
          <div className="auth-box-logo">
            <img
              className="auth-box-logo-image"
              src="https://cdn-assets-eu.frontify.com/s3/frontify-enterprise-files-eu/eyJwYXRoIjoiYm9zY2hcL2ZpbGVcL3lmTXlYd1ZFQnVYanRZTTdhTEp4LnBuZyJ9:bosch:kVU4tK9bs1CIt8zF0HFimB-Gph48m7OR7q4qrvja5Fo?width={width}"
              alt="Logo"
            />
          </div>

          <ExternalForm />
          <p className="auth-note">Logging in ensures a personalized experience and access to all our features</p>
          <a href="https://www.bosch.com/static/frontify/corporate-information.html" className="auth-info" rel="noopener noreferrer">
            Corporate Information
          </a>
        </div>
      </Box>
    </div>
  )
}

export default LoginPage
