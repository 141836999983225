import React, { useState } from 'react'
import { Icon, TableCell } from '@bosch/react-frok'
import { ITableCellsProps } from '../../interfaces/ITableProps'
import { downloadFile, getOutputById } from '../../service/apiService'
import { getIconByStatus, handleFileDateText, handleFileInfoText } from '../../service/filesTableHandlers'
import Output from '../../types/Output'
import FileResults from '../FileResults/FileResults'

const FilesTableCells: React.FunctionComponent<ITableCellsProps> = ({ file, userToken }) => {
  const [isFileResultsOpen, setIsFileResultsOpen] = useState(false)
  const [output, setOutput] = useState<Output>()

  const handleDownloadClick = () => {
    if (!file._id) {
      return
    }
    downloadFile(file.fileId, file._id, userToken)
  }

  const handleExtendClick = async (id: string | null | undefined) => {
    setIsFileResultsOpen(!isFileResultsOpen)
    if (!isFileResultsOpen && id) {
      const outputData = await getOutputById(userToken, id)
      if (outputData) {
        setOutput(outputData)
      }
    }
  }

  return (
    <>
      <TableCell className="file-name">
        {isFileResultsOpen ? (
          <Icon
            className="file-name-arrow"
            iconName="up"
            onClick={() => {
              handleExtendClick(file.ocrOutputResultId)
            }}
          />
        ) : (
          <Icon
            className="file-name-arrow"
            iconName="down"
            onClick={() => {
              handleExtendClick(file.ocrOutputResultId)
            }}
          />
        )}
        {file.fileId}
        <div className="file-info">{handleFileInfoText(file)}</div>
        {isFileResultsOpen && file.ocrOutputResultId !== undefined && file.ocrOutputResultId !== null && output ? (
          <FileResults fileResultsData={output} />
        ) : null}
        <div className={`file-info-status ${file.status}`}>
          <Icon iconName={getIconByStatus(file.status)} />
          Status: {file.status}
        </div>
      </TableCell>
      <TableCell className="file-size">{file.size + 'B'}</TableCell>
      <TableCell className="file-type">{file.fileId.split('.').pop()}</TableCell>
      <TableCell className="file-processing-date">{handleFileDateText(file.processingDate)}</TableCell>
      <TableCell className="file-upload-date">{handleFileDateText(file.uploadDate)}</TableCell>
      <TableCell className={`file-status ${file.status}`}>
        <div className="background">
          <Icon className="file-status-icon" iconName={getIconByStatus(file.status)} />
          {file.status}
        </div>
      </TableCell>
      <TableCell className="file-actions">
        <Icon iconName="download" onClick={handleDownloadClick} />
      </TableCell>
    </>
  )
}

export default FilesTableCells
