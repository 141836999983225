import React, { useEffect, useState } from 'react'
import './App.css'
import HomePage from './views/HomePage/HomePage'
import log from 'loglevel'
import LoginPage from './views/Login/LoginPage'
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth'

log.setLevel('error')

function App() {
  const [isCognitoAuthenticated, setIsCognitoAuthenticated] = useState(false)
  const [userToken, setUserToken] = useState<string | null>(null)

  const checkCognitoAuth = async () => {
    try {
      await getCurrentUser()
      const session = await fetchAuthSession()
      if (session.tokens?.accessToken) {
        setUserToken(session.tokens.accessToken.toString)
      }

      setIsCognitoAuthenticated(true)
    } catch (error) {
      return null
    }
  }

  useEffect(() => {
    checkCognitoAuth()
  }, [])

  if (isCognitoAuthenticated && userToken) {
    return <HomePage userToken={userToken} />
  } else {
    return <LoginPage />
  }
}

export default App
