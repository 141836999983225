import React from 'react'
import { TableCell } from '@bosch/react-frok'
import { handleFileDateText, handleFileResultsInfoText } from '../../service/filesTableHandlers'
import FileResults from '../../types/Output'

const FileResultsTableCells: React.FC<{ result: FileResults }> = ({ result }) => {
  return (
    <>
      <TableCell className="file-results-name">
        {result.fileId}
        <div className="file-results-info">{handleFileResultsInfoText(result)}</div>
      </TableCell>
      <TableCell className="file-results-petrol-station">{result.petrolStation !== '' ? result.petrolStation : 'null'}</TableCell>
      <TableCell className="file-results-date">{result.date !== '' ? handleFileDateText(result.date) : 'null'}</TableCell>
      <TableCell className="file-results-currency">{result.currency !== '' ? result.currency : 'null'}</TableCell>
      <TableCell className="file-results-paid">{result.paid !== '' ? result.paid : 'null'}</TableCell>
      <TableCell className="file-results-type-fuel">{result.typeFuel !== '' ? result.typeFuel : 'null'}</TableCell>
      <TableCell className="file-results-address">{result.address !== '' ? result.address : 'null'}</TableCell>
    </>
  )
}

export default FileResultsTableCells
