import env from "react-dotenv";

const environment = env.ENVIRONMENT_TYPE || 'Local';

let REDIRECT_URI: string;
let API_SERVICE_URL: string;

switch (environment) {
  case 'QA':
    REDIRECT_URI = "https://validation-ui.qa.digitalfueltwin-solutions.com"
    API_SERVICE_URL = "https://validation-service.qa.digitalfueltwin-solutions.com"
    break;
  case 'Prod':
    REDIRECT_URI = "https://validation-ui.prod.digitalfueltwin-solutions.com";
    API_SERVICE_URL = "https://validation-service.prod.digitalfueltwin-solutions.com";
    break;
  case 'Development':
    REDIRECT_URI = "https://validation-ui.development.digitalfueltwin-solutions.com";
    API_SERVICE_URL = "https://validation-service.development.digitalfueltwin-solutions.com";
    break;
  case 'Local':
    REDIRECT_URI = "http://localhost:3000/";
    API_SERVICE_URL = "https://validation-service.development.digitalfueltwin-solutions.com";
    break;
  default:
    REDIRECT_URI = "https://validation-ui.development.digitalfueltwin-solutions.com";
    API_SERVICE_URL = "https://validation-service.development.digitalfueltwin-solutions.com";
    break;
}

export const environmentConstants = {
  REDIRECT_URI,
  API_SERVICE_URL,
};
