import React from 'react'
import { Table, TableHead, TableRow, TableCell, TableBody } from '@bosch/react-frok'
import FilesTableCells from '../FilesTableCell'
import { ITableProps } from '../../../interfaces/ITableProps'
import './FilesTable.scss'

const FilesTable: React.FunctionComponent<ITableProps> = ({ files, userToken }) => {
  return (
    <div className="files-table">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell header secondary>
              File
            </TableCell>
            <TableCell header secondary>
              Size
            </TableCell>
            <TableCell header secondary>
              Type
            </TableCell>
            <TableCell header secondary>
              Process Date
            </TableCell>
            <TableCell header secondary>
              Upload Date
            </TableCell>
            <TableCell header secondary>
              Status
            </TableCell>
            <TableCell header secondary>
              Download
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map((file) => (
            <TableRow key={file.fileId}>
              <FilesTableCells file={file} userToken={userToken} />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default FilesTable
