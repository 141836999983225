import React, { useState } from 'react'
import { IMainUserFileProps } from '../../interfaces/IUserFilesProps'
import './UserFiles.scss'
import FilesTable from '../../atoms/Table/FilesTable/FilesTable'
import { getFilesMetadata } from '../../service/apiService'
import { Icon } from '@bosch/react-frok'
import FileSearch from '../../molecules/FileSearch/FileSearch'

const UserFiles: React.FunctionComponent<IMainUserFileProps> = ({
  filesMetadata,
  setFilesMetadata,
  setFilesNumberOfPages,
  userToken,
  filesPageNumber,
  filesPageLimit,
}) => {
  const [searchFilter, setSearchFilter] = useState<string>('')

  const handleRefresh = () => {
    setSearchFilter('')
    getFilesMetadata(setFilesMetadata, setFilesNumberOfPages, userToken, filesPageNumber, filesPageLimit, searchFilter, '', '')
  }

  return (
    <div className="main-user-files">
      <div className="box-content">
        <div className="title-and-refresh">
          <h4 className="title">Uploaded Files</h4>
          <Icon iconName="refresh-cloud" className="refresh-icon" onClick={handleRefresh} />
        </div>
        <FileSearch
          setFilesMetadata={setFilesMetadata}
          setFilesNumberOfPages={setFilesNumberOfPages}
          userToken={userToken}
          filesPageLimit={filesPageLimit}
          filesPageNumber={filesPageNumber}
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
        />
        <div className="user-files-table">
          {filesMetadata.length !== 0 && <FilesTable files={filesMetadata} userToken={userToken} />}
          {filesMetadata.length === 0 && (
            <div className="no-files">
              <p className="no-files-paragraph"> No files yet...</p>{' '}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default UserFiles
