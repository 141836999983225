import { Dispatch, SetStateAction } from 'react'
import { FileSortCheckbox } from '../types/FileSortCheckbox'
import { Params } from '../types/Params'

const defaultFilesOrder =
  'sortBy=fileId:ASC&sortBy=status:ASC&sortBy=type:ASC&sortBy=size:ASC&sortBy=processingDate:ASC&sortBy=uploadDate:ASC'

export const handleSortPriority = (
  index: number,
  newPriority: string,
  checkboxes: FileSortCheckbox[],
  setCheckboxes: Dispatch<SetStateAction<FileSortCheckbox[]>>,
) => {
  const newPriorityOrder = [...checkboxes]
  newPriorityOrder[index].priority = parseInt(newPriority) || 0
  setCheckboxes(newPriorityOrder)
}

export const toggleSortOrder = (index: number, setCheckboxes: Dispatch<SetStateAction<FileSortCheckbox[]>>) => {
  setCheckboxes((prev) => prev.map((option, i) => (i === index ? { ...option, order: option.order === 'ASC' ? 'DESC' : 'ASC' } : option)))
}

export const searchByString = (checkboxes: FileSortCheckbox[]): string => {
  return checkboxes
    .filter((checkbox) => checkbox.checked)
    .sort((c1, c2) => c1.priority - c2.priority)
    .map((checkbox) => checkbox.value)
    .join('&')
}

export const orderSortByString = (checkboxes: FileSortCheckbox[]): string => {
  return checkboxes
    .filter((checkbox) => checkbox.checked)
    .sort((c1, c2) => c1.priority - c2.priority)
    .map((checkbox) => `sortBy=${encodeURIComponent(checkbox.value)}:${encodeURIComponent(checkbox.order)}`)
    .join('&')
}

export const handleCheckAll = (checkboxes: FileSortCheckbox[], setCheckboxes: Dispatch<SetStateAction<FileSortCheckbox[]>>) => {
  const allChecked = checkboxes.map((checkbox) => ({
    ...checkbox,
    checked: true,
  }))
  setCheckboxes(allChecked)
}

export const handleUncheckAll = (checkboxes: FileSortCheckbox[], setCheckboxes: Dispatch<SetStateAction<FileSortCheckbox[]>>) => {
  const allChecked = checkboxes.map((checkbox) => ({
    ...checkbox,
    checked: false,
  }))
  setCheckboxes(allChecked)
}

export const handleChange = (
  position: number,
  checkboxes: FileSortCheckbox[],
  setCheckboxes: Dispatch<SetStateAction<FileSortCheckbox[]>>,
) => {
  const updatedCheckboxes = checkboxes.map((checkbox, index) => {
    if (index === position) {
      return { ...checkbox, checked: !checkbox.checked }
    }
    return checkbox
  })
  setCheckboxes(updatedCheckboxes)
}

export const getFilesQueryParams = (
  pageNumber: number,
  pageLimit: number,
  searchFilter: string,
  searchBy: string,
  sortBy: string,
): string => {
  const params: Params = {
    page: pageNumber,
    limit: pageLimit,
    search: searchFilter,
  }
  let queryParams = 'page=' + pageNumber + '&limit=' + pageLimit

  if (searchFilter !== '') {
    queryParams = Object.keys(params)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
      .join('&')

    if (searchBy !== '') {
      queryParams = queryParams + '&searchBy=' + searchBy
    } else {
      queryParams = queryParams + '&searchBy=fileId'
    }

    if (sortBy !== '' && sortBy !== defaultFilesOrder) {
      queryParams = queryParams + '&' + sortBy
    }

    return queryParams
  } else {
    if (sortBy !== '' && sortBy !== defaultFilesOrder) {
      queryParams = queryParams + '&' + sortBy
      return queryParams
    }
    return queryParams + defaultFilesOrder
  }
}
