import React from 'react'
import { IFileResultsProps } from '../../interfaces/ITableProps'
import './FileResults.scss'
import { handleFileDateText } from '../../service/filesTableHandlers'

const FileResults: React.FunctionComponent<IFileResultsProps> = ({ fileResultsData }) => {
  return (
    <div className="files-results-table">
      <div className="petrol-station">
        <p className="petrol-station title">Petrol Station:</p>
        <p className="petrol-station results">{' ' + fileResultsData.petrolStation}</p>
      </div>
      <div className="date">
        <p className="date title">Date:</p>
        <p className="date results">{fileResultsData.date !== '' ? handleFileDateText(fileResultsData.date) : 'null'}</p>
      </div>
      <div className="currency">
        <p className="currency title">Currency:</p>
        <p className="currency results">{' ' + fileResultsData.currency}</p>
      </div>
      <div className="amount_paid">
        <p className="amount_paid title">Amount Paid:</p>
        <p className="amount_paid results">{' ' + fileResultsData.paid}</p>
      </div>
      <div className="type_of_fuel">
        <p className="type_of_fuel title">Type of Fuel:</p>
        <p className="type_of_fuel results">{' ' + fileResultsData.typeFuel}</p>
      </div>
      <div className="address">
        <p className="address title">Address:</p>
        <p className="address results">{' ' + fileResultsData.address !== '' ? fileResultsData.address : '-'}</p>
      </div>
    </div>
  )
}

export default FileResults
