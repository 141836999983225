import axios from 'axios'
import { FILES_METADATA_URL, FILES_OUTPUT_URL, FILES_URL } from '../config/apiConfig'
import { Dispatch, SetStateAction } from 'react'
import { Status } from '../types/Status'
import FileData from '../types/FileData'
import FileMetadata from '../types/FileMetadata'
import FileResults from '../types/Output'
import { getResultsQueryParams } from './sortResultsUtils'
import { getFilesQueryParams } from './sortFilesUtils'
import Output from '../types/Output'

export const uploadFile = async (formData: FormData, accountToken: string, setUploadStatus: Dispatch<SetStateAction<Status>>) => {
  try {
    const response = await axios.post(FILES_URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${accountToken}`,
      },
    })
    return response
  } catch (e) {
    setUploadStatus('fail')
  }
}

export const getAllFiles = async (setFiles: Dispatch<SetStateAction<FileData[]>>, userToken: string | undefined) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${userToken}` }
  if (userToken) {
    try {
      const response = await axios.get(FILES_URL)

      if (!response?.status) throw Error('Did not receive expected files data')

      const files = await response.data

      setFiles(files.docs)
    } catch (e) {
      setFiles([])
    }
  } else {
    throw Error('User token undefined')
  }
}

export const downloadFile = async (fileName: string, id: string, userToken: string | undefined) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${userToken}` }
  if (userToken) {
    try {
      const response = await axios.get(FILES_METADATA_URL + '/' + id, {
        responseType: 'blob',
      })

      if (!response?.status) throw Error('Did not receive expected download data')

      const blob = await response.data
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')

      a.href = url
      a.download = fileName
      document.body.appendChild(a)
      a.click()
      a.remove()
    } catch (e) {
      return null
    }
  } else {
    throw Error('User token undefined')
  }
}

export const getFilesMetadata = async (
  setFilesMetadata: Dispatch<SetStateAction<FileMetadata[]>>,
  setFilesNumberOfPages: Dispatch<SetStateAction<number>>,
  userToken: string | undefined,
  filesPageNumber: number,
  filesPageLimit: number,
  searchFilter: string,
  searchBy: string,
  sortBy: string,
) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${userToken}` }

  if (userToken) {
    try {
      const queryParams = getFilesQueryParams(filesPageNumber, filesPageLimit, searchFilter, searchBy, sortBy)
      const response = await axios.get(FILES_METADATA_URL + '?' + queryParams)
      if (!response?.status) throw Error('Did not receive expected files data')

      const files = await response.data
      setFilesMetadata(files.docs)
      setFilesNumberOfPages(files.totalPages)
    } catch (e) {
      setFilesMetadata([])
    }
  } else {
    throw Error('User token undefined')
  }
}

export const getFilesResults = async (
  setFilesResults: Dispatch<SetStateAction<FileResults[]>>,
  setResultsNumberOfPages: Dispatch<SetStateAction<number>>,
  userToken: string | undefined,
  resultsPageNumber: number,
  resultsPageLimit: number,
  searchResultsFilter: string,
  searchBy: string,
  sortBy: string,
) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${userToken}` }

  if (userToken) {
    try {
      const queryParams = getResultsQueryParams(resultsPageNumber, resultsPageLimit, searchResultsFilter, searchBy, sortBy)
      const response = await axios.get(FILES_OUTPUT_URL + '?' + queryParams)
      if (!response?.status) throw Error('Did not receive expected files data')

      const outputs = await response.data
      setFilesResults(outputs.docs)
      setResultsNumberOfPages(outputs.totalPages)
    } catch (e) {
      setFilesResults([])
    }
  } else {
    throw Error('User token undefined')
  }
}

export const getOutputById = async (userToken: string | undefined, outputId: string) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${userToken}` }
  if (userToken) {
    try {
      const response = await axios.get(FILES_OUTPUT_URL + '/' + outputId)

      if (!response?.status) throw Error('Did not receive expected output data')

      const output = await response.data
      return output as Output
    } catch (e) {
      return null
    }
  } else {
    throw Error('User token undefined')
  }
}
