import React from 'react'
import cv, { Mat } from 'opencv-ts'

export function calculateImageQuality(image: Mat) {
  const threshMultiplier = 65
  const staticThreshold = 10
  const dynamicThreshold = ((image.cols * image.rows) / (1000 * 1000)) * threshMultiplier
  let isBlurred = false

  const src = image
  const dst = new cv.Mat()
  const mean = new cv.Mat()
  const stddev = new cv.Mat()
  const gray = new cv.Mat()

  cv.cvtColor(src, gray, cv.COLOR_BGR2GRAY)
  cv.Laplacian(gray, dst, cv.CV_64F, 1, 1, 0, cv.BORDER_DEFAULT)
  cv.meanStdDev(dst, mean, stddev)

  if (stddev.data64F[0] > staticThreshold) {
    if (stddev.data64F[0] - dynamicThreshold > 1) {
      isBlurred = true
    } else {
      isBlurred = false
    }
  } else {
    isBlurred = true
  }

  return isBlurred
}

export function imageReadAndCheck(droppedFile: File, setWarningMessage: React.Dispatch<React.SetStateAction<string>>) {
  const reader = new FileReader()
  reader.onload = async (event: ProgressEvent<FileReader>) => {
    if (event.target && event.target.result) {
      const imageUrl = event.target.result as string
      const imgElement = document.createElement('img')
      imgElement.src = imageUrl

      imgElement.onload = () => {
        const image = cv.imread(imgElement)
        const isBlurred = calculateImageQuality(image)

        if (isBlurred) {
          setWarningMessage('Image quality check has not passed')
        } else {
          setWarningMessage('')
        }
      }
    }
  }
  reader.readAsDataURL(droppedFile)
}

export function handleCanBeUploaded(
  checkQuality: boolean,
  warningMessage: string,
  setWarningMessage: React.Dispatch<React.SetStateAction<string>>,
  setCanBeUploaded: React.Dispatch<React.SetStateAction<boolean>>,
) {
  if (checkQuality) {
    if (warningMessage !== '') {
      setCanBeUploaded(false)
    } else {
      setCanBeUploaded(true)
    }
  } else if (warningMessage !== 'File is not an image') {
    setCanBeUploaded(true)
    setWarningMessage('')
  } else {
    setCanBeUploaded(false)
  }
}
