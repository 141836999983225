import { Dispatch, SetStateAction } from 'react'
import { Status } from '../types/Status'
import { uploadFile } from './apiService'

export const handleUploadClick = async (
  canBeUploaded: boolean,
  files: File[],
  setUploadStatus: Dispatch<SetStateAction<Status>>,
  userToken: string | undefined,
  setUploadImage: Dispatch<SetStateAction<string | undefined>>,
) => {
  if (canBeUploaded) {
    setUploadStatus('start')
    const fileToUpload = files[0]
    const formData = new FormData()
    formData.append('file', fileToUpload)

    if (fileToUpload) {
      if (userToken) {
        const response = await uploadFile(formData, userToken, setUploadStatus)
        if (response?.status) {
          setUploadStatus('success')
          setUploadImage(undefined)
          files = []
        } else {
          setUploadStatus('fail')
        }
      } else {
        setUploadStatus('fail')
      }
    } else {
      setUploadStatus('no-file')
    }
  } else {
    setUploadStatus('fail')
  }
}

export const handleFileText = (filename: string): string => {
  return filename.length > 27 ? filename.slice(0, 27) + '...' : filename
}

export const getUploadMessage = (status: Status, file: File): string => {
  if (file && file.name) {
    const fileName = handleFileText(file.name)
    switch (status) {
      case 'start':
        return 'Uploading ' + fileName + '...'
      case 'success':
        return 'Uploaded ' + fileName
      case 'fail':
        return 'Failed to upload ' + fileName
      default:
        return ''
    }
  } else {
    if (status === 'no-file') {
      return 'Please select a file'
    } else {
      return ''
    }
  }
}
