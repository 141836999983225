import React, { useState } from 'react'
import './UserFileResults.scss'
import FileResultsTable from '../../atoms/Table/FileResultsTable/FileResultsTable'
import { Icon } from '@bosch/react-frok'
import { getFilesResults } from '../../service/apiService'
import ResultsSearch from '../../molecules/ResultsSearch/ResultsSearch'
import { IMainUserResultsProps } from '../../interfaces/IUserResultsProps'

const UserFileResults: React.FunctionComponent<IMainUserResultsProps> = ({
  results,
  setFilesResults,
  setResultsNumberOfPages,
  userToken,
  resultsPageNumber,
  resultsPageLimit,
}) => {
  const [searchFilter, setSearchFilter] = useState<string>('')

  const handleRefresh = () => {
    setSearchFilter('')
    getFilesResults(setFilesResults, setResultsNumberOfPages, userToken, resultsPageNumber, resultsPageLimit, searchFilter, '', '')
  }

  return (
    <div className="main-user-file-results">
      <div className="results-box-content">
        <div className="results-title-and-refresh">
          <h4 className="results-title">File Results</h4>
          <Icon iconName="refresh-cloud" className="results-refresh-icon" onClick={handleRefresh} />
        </div>
        <ResultsSearch
          setFilesResults={setFilesResults}
          setResultsNumberOfPages={setResultsNumberOfPages}
          userToken={userToken}
          resultsPageNumber={resultsPageNumber}
          resultsPageLimit={resultsPageLimit}
          searchResultsFilter={searchFilter}
          setSearchResultsFilter={setSearchFilter}
        />
        <div className="user-file-results-table">
          {results.length !== 0 && <FileResultsTable results={results} userToken={userToken} />}
          {results.length === 0 && (
            <>
              <FileResultsTable results={results} userToken={userToken} />
              <p className="no-results">No results yet. Try to refresh.</p>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default UserFileResults
