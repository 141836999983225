import React from 'react'
import { Icon } from '@bosch/react-frok'
import './FileWarning.scss'
import { IFileWarningProps } from '../../interfaces/IUserFilesProps'

const FileWarning: React.FunctionComponent<IFileWarningProps> = ({ warningMessage }) => {
  if (warningMessage !== '') {
    return (
      <div className="file-warning">
        <Icon iconName="alert-warning" />
        <p id="warning-paragraph">{warningMessage}</p>
      </div>
    )
  } else {
    return null
  }
}

export default FileWarning
