import FileMetadata from '../types/FileMetadata'
import FileResults from '../types/Output'

export const handleFileDateText = (fileDate: string | null): string => {
  if (fileDate) return fileDate.split('T')[0] + ' ' + fileDate.split('T')[1].substring(0, 5)
  else return 'null'
}

export const handleFileInfoText = (file: FileMetadata): string => {
  const processingDate = file.processingDate ? file.processingDate : ''
  return file
    ? 'size: ' +
        file.size +
        ' B,' +
        ' type: ' +
        file.fileId.split('.').pop() +
        ', processing date: ' +
        handleFileDateText(processingDate) +
        ', upload date: ' +
        handleFileDateText(file.uploadDate)
    : ''
}

export const handleFileResultsFullInfoText = (fileResults: FileResults): string => {
  return fileResults
    ? 'Petrol Station: ' +
        fileResults.petrolStation +
        ', Date: ' +
        handleFileDateText(fileResults.date) +
        ', Currency: ' +
        fileResults.currency +
        ', Paid: ' +
        fileResults.paid +
        ', Fuel Type: ' +
        fileResults.typeFuel +
        ', Address: ' +
        fileResults.address
    : ''
}

export const handleFileResultsInfoText = (fileResults: FileResults): string => {
  return fileResults ? 'Date: ' + handleFileDateText(fileResults.date) + ', Currency: ' + fileResults.currency : ''
}

export const getNumberOfPages = (numberOfFiles: number, pageLimit: number): number => {
  if (pageLimit === 0) {
    return 1
  }
  if (numberOfFiles >= pageLimit) {
    const numberOfPages = numberOfFiles / pageLimit
    const numberOfPagesRounded = Math.round(numberOfPages)
    if (numberOfPages - numberOfPagesRounded > 0) {
      return numberOfPagesRounded + 1
    } else {
      return numberOfPagesRounded
    }
  } else {
    return 1
  }
}

export const getIconByStatus = (status: string): string => {
  switch (status) {
    case 'done':
      return 'alert-success'
    case 'toDo':
      return 'alert-info'
    case 'pending':
      return 'alert-warning'
    case 'error':
      return 'alert-error'
    default:
      return 'alert-info'
  }
}
