import React, { useEffect, useState } from 'react'
import { IUserFileSearchProps } from '../../interfaces/IUserFilesProps'
import './FileSearch.scss'
import { getFilesMetadata } from '../../service/apiService'
import { Box, Button, Checkbox, SearchForm } from '@bosch/react-frok'
import {
  handleChange,
  handleCheckAll,
  handleSortPriority,
  handleUncheckAll,
  orderSortByString,
  searchByString,
  toggleSortOrder,
} from '../../service/sortFilesUtils'

const FileSearch: React.FunctionComponent<IUserFileSearchProps> = ({
  setFilesMetadata,
  setFilesNumberOfPages,
  userToken,
  filesPageNumber,
  filesPageLimit,
  searchFilter,
  setSearchFilter,
}) => {
  const [searchString, setSearchString] = useState<string>('')
  const [searchByClick, setSearchByClicked] = useState(false)
  const [checkboxes, setCheckboxes] = useState([
    { label: 'Name', value: 'fileId', checked: true, order: 'ASC', priority: 0 },
    { label: 'Status', value: 'status', checked: true, order: 'ASC', priority: 3 },
    { label: 'Type', value: 'type', checked: true, order: 'ASC', priority: 4 },
    { label: 'Size', value: 'size', checked: true, order: 'ASC', priority: 5 },
    { label: 'Process Date', value: 'processingDate', checked: true, order: 'DESC', priority: 2 },
    { label: 'Upload Date', value: 'uploadDate', checked: true, order: 'DESC', priority: 1 },
  ])

  const checkedValuesString = searchByString(checkboxes)
  const orderValuesString = orderSortByString(checkboxes)

  const handleSearchString = (event: any) => {
    setSearchString(event.target.value)
  }

  const handleFilesSearch = () => {
    setSearchFilter(searchString)
  }

  const handleResetSearch = () => {
    setSearchFilter('')
  }

  const handleSearchByClick = () => {
    setSearchByClicked(!searchByClick)
  }

  useEffect(() => {
    getFilesMetadata(
      setFilesMetadata,
      setFilesNumberOfPages,
      userToken,
      filesPageNumber,
      filesPageLimit,
      searchFilter,
      checkedValuesString,
      orderValuesString,
    )
  }, [filesPageNumber, searchFilter, checkedValuesString, orderValuesString])

  return (
    <>
      <div className="search-filters">
        <div className="search">
          <SearchForm
            onChange={handleSearchString}
            onReset={handleResetSearch}
            onSubmit={handleFilesSearch}
            searchField={{
              id: 'searchFieldId',
            }}
            suggestions={null}
          />
        </div>
        <div className={searchByClick ? 'search-by-button-open' : 'search-by-button'}>
          <Button
            onClick={handleSearchByClick}
            icon={{
              iconName: 'arrow-up-down',
              title: 'File Order',
            }}
            label="Search and Order Files by"
          />
        </div>
      </div>
      {searchByClick ? (
        <Box shadow className="search-and-order-by-box">
          <div className="select-all">
            <p
              className="select-paragraph"
              onClick={() => {
                handleCheckAll(checkboxes, setCheckboxes)
              }}
            >
              Check All
            </p>
            <p
              className="select-paragraph"
              onClick={() => {
                handleUncheckAll(checkboxes, setCheckboxes)
              }}
            >
              Uncheck All
            </p>
            <p className="priority-paragraph">Sort Order</p>
          </div>
          <div className="checkboxes-and-orders">
            {checkboxes.map((checkbox, index) => (
              <div key={'search' + index} className="checkbox-and-order">
                <Checkbox
                  checked={checkbox.checked}
                  onChange={() => handleChange(index, checkboxes, setCheckboxes)}
                  id={`checkbox-files-${index}`}
                  label={checkbox.label}
                  className="checkbox"
                />
                <p
                  id={`asc-${index}`}
                  className={`order-asc ${checkbox.order === 'ASC' ? 'selected' : ''}`}
                  onClick={() => toggleSortOrder(index, setCheckboxes)}
                >
                  ASC
                </p>
                <p
                  id={`desc-${index}`}
                  className={`order-desc ${checkbox.order === 'DESC' ? 'selected' : ''}`}
                  onClick={() => toggleSortOrder(index, setCheckboxes)}
                >
                  DESC
                </p>
                <input
                  className="sort-order-priority"
                  id={`priority-${index}`}
                  type="number"
                  min="-10"
                  max="10"
                  value={checkbox.priority.toString()}
                  onChange={(e) => handleSortPriority(index, e.target.value, checkboxes, setCheckboxes)}
                />
              </div>
            ))}
          </div>
        </Box>
      ) : null}
    </>
  )
}

export default FileSearch
