import { AuthProviderProps } from "react-oidc-context";
import {environmentConstants} from '../config'


const oidcConfig: AuthProviderProps = {
    authority: "https://p3.authz.bosch.com/realms/digital-fuel-twin-test",
    client_id:  "digital-fuel-twin-validation-webapp-dev",
    redirect_uri: environmentConstants.REDIRECT_URI || "https://validation-ui.development.digitalfueltwin-solutions.com",
    metadata: {
        authorization_endpoint: "https://p3.authz.bosch.com/auth/realms/digital-fuel-twin-test/protocol/openid-connect/auth",
        token_endpoint: "https://p3.authz.bosch.com/auth/realms/digital-fuel-twin-test/protocol/openid-connect/token",
        issuer: "https://p3.authz.bosch.com/auth/realms/digital-fuel-twin-test",
        userinfo_endpoint: "https://p3.authz.bosch.com/auth/realms/digital-fuel-twin-test/protocol/openid-connect/userinfo",
        end_session_endpoint: "https://p3.authz.bosch.com/auth/realms/digital-fuel-twin-test/protocol/openid-connect/logout"
    },
    onSigninCallback: (): void => {
        window.history.replaceState({}, document.title, window.location.pathname);
    },
};

export default oidcConfig;
