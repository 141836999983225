import React from 'react'
import { Button } from '@bosch/react-frok'
import './UploadButton.scss'
import { IUploadButtonProps } from '../../interfaces/IUploadButtonProps'
import { handleUploadClick } from '../../service/uploadHandlers'

const UploadButton: React.FunctionComponent<IUploadButtonProps> = ({
  newFilesToUpload,
  userToken,
  setUploadStatus,
  canBeUploaded,
  setUploadImage,
}) => {
  return (
    <div className="upload-button">
      <Button
        icon={{
          iconName: 'cloud-upload',
          title: 'Upload',
        }}
        label="Upload File"
        onClick={() => {
          handleUploadClick(canBeUploaded, newFilesToUpload, setUploadStatus, userToken, setUploadImage)
        }}
      />
    </div>
  )
}

export default UploadButton
